<template>
  <v-card>
    <v-container>
      <v-card-title>
        <span class="pl-1 primary--text"
          >Editar configuración del usuario:
          {{ configUsuario.dobleFactorConfigUsuario }}</span
        >
      </v-card-title>

      <v-card-text class="py-0">
        <v-form
          v-model="isConfigFormValid"
          ref="config-usuario-form"
          form="config-usuario-form"
          id="config-usuario-form"
          @submit.prevent="save()"
        >
          <v-row>
            <v-col cols="8" class="py-0 px-0">
              <v-subheader class="py-0 px-0">
                <v-switch
                  class="ml-4"
                  v-model="dobleFactor"
                  dense
                  outlined
                  @change="toggleDobleFactorSwitch()"
                ></v-switch>
                Habilitar doble factor de autenticación
              </v-subheader>
            </v-col>
            <v-col cols="3" class="py-0 px-0">
              <v-text-field
                v-model="diasVigencia"
                outlined
                dense
                label="Días de vigencia"
                v-mask="'####'"
                suffix="días"
                :rules="
                  dobleFactor
                    ? rules.required.concat([
                        rules.minMaxNumber(diasVigencia, 1, 9999),
                      ])
                    : []
                "
                :disabled="!dobleFactor"
              ></v-text-field> </v-col
          ></v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal"> Cancelar </v-btn>
        <v-btn
          type="submit"
          :disabled="!isConfigFormValid"
          :loading="btnIsLoading"
          form="config-usuario-form"
          color="primary"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import { mask } from "vue-the-mask";

export default {
  name: "EditConfigDobleFactorXUsuario",
  directives: { mask },
  props: {
    configUsuario: { type: Object },
  },
  data: () => ({
    isConfigFormValid: false,
    btnIsLoading: false,
    rules: rules,
    dobleFactor: false,
    diasVigencia: 0,
  }),
  created() {
    this.setConfigUsuario();
  },
  methods: {
    ...mapActions({
      saveConfiguracionXUsuario: "user/saveConfiguracionXUsuario",
      setAlert: "user/setAlert",
    }),
    setConfigUsuario() {
      this.dobleFactor = this.configUsuario.dobleFactorConfigHabilitado;
      this.diasVigencia = this.configUsuario.dobleFactorConfigDias;
    },
    closeModal() {
      this.$emit("closeAndReload");
    },
    toggleDobleFactorSwitch() {
      if (!this.dobleFactor) this.diasVigencia = 0;
      else this.diasVigencia = 30;
    },
    async save() {
      this.btnIsLoading = true;
      const data = {
        usuarioId: this.configUsuario.usuarioId,
        habilitado: this.dobleFactor,
        dias: this.diasVigencia,
      };
      try {
        const response = await this.saveConfiguracionXUsuario(data);
        if (response.status === 200 && response.data.data) {
          this.setAlert({ type: "success", message: "Guardado con éxito." });
          this.closeModal();
          this.btnIsLoading = false;
        }
      } catch (error) {
        this.btnIsLoading = false;
      }
    },
  },
};
</script>

<style></style>
